<template>
  <div class="trade">
    <div class="nav-title">フィードバック</div>
    <div class="search-query-container">
      <div class="search-query">
        <el-form :form="searchForm">
          <el-row :gutter="20">
            <el-col>
              <el-form-item :label="$t('时间区间 dateRange')">
                <search-date class="search-date" :data.sync="searchForm.searchDate" style="width: 100%;" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('企业Code entCode')">
                <el-input v-model="searchForm.entCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('企业名 entName')">
                <el-input v-model="searchForm.entName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('分类 title')">
                <!-- <el-input v-model="searchForm.title"></el-input> -->
                <el-select v-model="searchForm.title" style="width:100%;">
                  <el-option v-for="(item, index) in titleList" :key="index" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('内容 content')">
                <el-input v-model.number="searchForm.content"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24" v-if="false">
              <el-form-item :label="$t('评分 rating')">
                <el-input v-model.number="searchForm.rating"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('OEM oem')">
                <el-input v-model="searchForm.oem"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="search-btn">
          <el-button class="cancel" @click="resetSearchQuery">{{ $t("searchReset") }}</el-button>
          <el-button class="confirm" @click="getList(1, currentPageSize)" :disabled="loading">{{
            $t("search")
          }}</el-button>
        </div>
      </div>
    </div>

    <div class="panel-sub-title " style="display: flex;flex-direction: row-reverse;">
      <a :href="'/apiproxy/edge/reviews/download?' + getDownloadParams()" download target="_blank"
        ><el-button class="cancel" icon="el-icon-download">
          下载 CSV 表格
        </el-button></a
      >
    </div>

    <div></div>
    <div class="result-background" style="margin-top: 30px;">
      <div class="result-table">
        <el-table :data="feedbackList" style="width: 100%">
          <!-- <el-table-column prop="_id" label="ID" width="180"></el-table-column> -->
          <el-table-column type="expand">
            <template v-slot:default="props">
              <el-form label-position="top" style="padding:0 48px;">
                <el-form-item label="内容">
                  <p style="word-break: break-all;white-space: normal;line-height: 20px;">
                    {{ props.row.content }}
                  </p>
                </el-form-item>
                <el-form-item label="上传文件" v-if="props.row.fileList.length > 0">
                  <img
                    v-for="(item, index) in props.row.fileList"
                    :src="$baseUrl + '/edge/download?fileName=' + item"
                    :key="item"
                    fit="cover"
                    style="width:100px;height:100px;margin-top:5px;margin-bottom:5px;margin-right:10px;border:1px solid #d9d9d9;border-radius: 6px;"
                  ></img>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="platform" label="系统名称"></el-table-column>
          <el-table-column prop="oem" label="OEM"></el-table-column>
          <el-table-column prop="entCode" label="企业Code"></el-table-column>
          <el-table-column prop="entName" label="企业名"></el-table-column>
          <el-table-column prop="title" label="分类"></el-table-column>
          <!-- <el-table-column prop="content" label="内容"></el-table-column> -->
          <!-- <el-table-column prop="rating" label="评分"></el-table-column> -->
          <!-- <el-table-column prop="type" label="评分种类"></el-table-column> -->
          <!-- <el-table-column prop="fileList" label="上传文件"></el-table-column>-->
          <el-table-column prop="userName" label="用户名"></el-table-column>
          <el-table-column prop="timestamp" label="提出时间" width="200">
            <template slot-scope="scope">
              {{ new Date(scope.row.timestamp).toLocaleString() }}
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          layout=" prev, pager, next, sizes, total"
          :total="total"
          :page-size="currentPageSize"
          :page-sizes="[10, 15, 20]"
          :current-page="currentPage"
          @current-change="currentChange"
          @size-change="sizeChange"
          class="common-pagination"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import pagination from "@/mixins/pagination";
export default {
  mixins: [pagination],
  data() {
    return {
      feedbackList: [],
      titleList: [
        "ダッシュボード関係",
        "取引関係",
        "ポイント関係",
        "入金関係",
        "集計関係",
        "通知関係",
        "店舗関係",
        "端末関係",
        "アカウント関係",
        "マニュアル関係",
        "その他"
      ],
      loading: false,
      searchForm: {
        searchDate: [
          this.moment()
            .startOf("day")
            .format("YYYY/MM/DD HH:mm:ss"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD HH:mm:ss")
        ],
        entCode: "",
        entName: "",
        title: "",
        rating: "",
        userName: "",
        content: "",
        type: "sendBack"
      }
    };
  },
  mounted: function() {
    this.getList(1, this.currentPageSize);
  },
  methods: {
    getDownloadParams() {
      const { searchDate, entCode, entName, title, userName, rating, type,content,oem } = this.searchForm;
      const startTime = searchDate && searchDate[0];
      const endTime = searchDate && searchDate[1];
      let params = {
        page: 1,
        pageSize: 9999,
        startTime,
        endTime,
        entCode,
        entName,
        title,
        userName,
        rating,
        type,
        content,
        oem
      };

      // 移除 params 中的 undefined 和 null 属性
      params = Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));

      return new URLSearchParams(params).toString();
    },
    getList(page = 1, pageSize = this.currentPageSize) {
      this.currentPage = page;
      const { searchDate, entCode, entName, title, userName, type,content,oem } = this.searchForm;
      const startTime = searchDate[0];
      const endTime = searchDate[1];
      let params = {
        page,
        pageSize,
        startTime,
        endTime,
        entCode,
        entName,
        title,
        userName,
        type,
        content,
        oem
      };
      if (this.searchForm.rating) {
        params.rating = this.searchForm.rating;
      }
      Axios.get(`/apiproxy/edge/reviews`, {
        params
      }).then(res => {
        console.log(res);
        if (res.status === 200 || res.statusText === "OK") {
          this.feedbackList = res.data.result;
          this.total = res.data.total;
        }
      });
    },
    resetSearchQuery() {
      this.searchForm = {
        searchDate: [
          this.moment()
            .startOf("day")
            .format("YYYY/MM/DD HH:mm:ss"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD HH:mm:ss")
        ],
        entCode: "",
        entName: "",
        title: "",
        rating: "",
        userName: "",
        content: "",
        oem:"",
        type: "sendBack"
      };
    }
  },
  computed: {
    activeMenu() {
      return this.$route.path;
    }
  }
};
</script>
<style scoped>
.setting-nav-container {
  display: block;
}

.setting-sider {
  float: left;
  width: 260px;
}

.setting-view {
  margin-left: 280px;
  padding-left: 0;
  display: block;
}
</style>
